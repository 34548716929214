import React, { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';
import CustomContextMenu from './context-menu';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isRequestPage, setIsRequestPage] = useState(false)
  const location = useLocation();


  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setIsRequestPage(location.pathname.includes('requests/create') || location.pathname.includes('register'));
  }, [location]);

  return (
    <header>
      <Navbar className={`navbar-expand-sm navbar-toggleable-sm ${isRequestPage && 'bg-white'} ng-white box-shadow pt-3 pb-3`} container dark>
        <NavbarBrand className={`${isRequestPage ? 'text-dark' : 'text-light'} font-bold text-4xl`} tag={Link} to="/">KGH</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar}  className="mr-2 bg-slate-600 mb-4" />
        <Collapse className="d-sm-inline-flex flex-sm-row " isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem style={{ width: '100%' }}>
              <CustomContextMenu isRequestPage={isRequestPage} />
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

