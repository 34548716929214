import { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, Upload, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import {
  AreaOfExpertise,
  CantonDto,
  CodebookDto,
  MunicipalityDto,
  RequestType,
  StateEntityDto,
} from "api/models";
import { Tag } from "components/tags/tags";
import { UploadOutlined } from "@ant-design/icons";
import "./create-request.css";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import { PublicApi } from "api/api";
import { TagInput } from "container/styled";
import TextArea from "antd/es/input/TextArea";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { useNavigate, useParams } from "react-router-dom";
import { AreaOfExpertiseDescriptions } from "api/models/area-of-expertise-enum";
import { RequestCompanyType } from "api/models/request-company-type";
import openNotificationWithIcon from "utility/notification";

const publicApi = new PublicApi();

const { Option } = Select;

const CreateRequestsForm = () => {
  /**
   * Transalte
   */
  const { t } = useTranslation();

  const navigate = useNavigate();

  /**
   * Forms
   */
  const [form] = Form.useForm();

  // const [searchParams] = useSearchParams();
  const { type } = useParams();
  const parsedType = parseInt(type ?? "0") as RequestType;

  /**
   * Context API
   */
  const commonData = useContext(CommonDataContext) as any;
  const { municipalities, cantons, stateEntities } = commonData;

  /**
   * State
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [businessActivities, setBusinessActivities] = useState<
    CodebookDto[] | null | undefined
  >([]);
  const [certificateNumbers, setCertificateNumbers] = useState<string[]>([]);
  const [certificateNumbersExpanded, setCertificateNumbersExpanded] = useState<
    string[]
  >([]);
  const [certificateNumberValidity, setCertificateNumberValidity] = useState<{
    checking: boolean;
    valid: boolean;
    errorMessage: string;
  }>({ checking: false, valid: true, errorMessage: "" });

  /**
   * Statics
   */
  const isRegistrationOfServiceCompany =
    parsedType === RequestType.RegistrationAndLicensingOfServiceCompanies;
  const isRegistrationOfImportersExporters =
    parsedType === RequestType.RegistrationAndLicensingOfImportersExporters;

  /**
   * Make request
   */
  const onFinish = async (data: any) => {
    setIsSubmitting(true);
    await searchForContactPersonEmail();
    await searchForCompany();

    if (isFormInvalid() || !(certificateNumberValidity.valid)) {
      let errorAlias = "requests:create.company-not-found";
      let errorDesc = "You are already part of the system, please sign in with your credentials and make requests from there!";
      if (!certificateNumberValidity.valid) {
        errorAlias = "requests:create.certificate-invalid";
        errorDesc = certificateNumberValidity.errorMessage ?? "Provided certificate number is not valid";
      }

      openNotificationWithIcon(
        "error",
        t(errorAlias, errorDesc)
      );
      setIsSubmitting(false);
      return;
    }
    const languageId =
      localStorage.getItem("i18nextLanguageId") ||
      "64924d45-7f38-11ee-85aa-0242ac110004";
    try {
      setIsSubmitting(true);
      // const request = data
      data.type = parsedType;
      if (parsedType === 2) {
        data.totalNumberOfServiceTechnians = parseInt(
          data.totalNumberOfServiceTechnians || 0
        );
        data.certificationNumbers = certificateNumbers;
      }
      data.languageId = languageId;

      const municipality = municipalities.find(
        (res: any) => res.id === data.municipalityId
      );
      debugger

      const companyTypeLabels: Record<any, string> = {
        1: 'Company',
        2: 'Entrepreneur',
        3: 'Importer',
        4: 'Exporter',
        5: 'Importer/Exporter',
      };
      var companyTypeName = companyTypeLabels[data.companyType];

      data.stateEntityId = form.getFieldValue("entityId");
      // var conformationData = data;
      // conformationData.stateEntityId = "";
      const response = await publicApi.apiPublicRequestsSubmitPost(data);
      navigate("/confirmation", {
        state: {
          requestId: response.data,
          // ...conformationData,
          ...data,
          municipality: municipality?.name,
          canton: municipality?.cantonName,
          entity: municipality?.entityName,
          areaOfExpertise: AreaOfExpertiseDescriptions[data.areaOfExpertise as AreaOfExpertise],
          companyType: companyTypeName,
        },
      });
      form.resetFields();
      openNotificationWithIcon(
        "success",
        t("requests:create-success", "Request created successfully")
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormInvalid = () =>
    form.getFieldsError().some((item) => item.errors.length > 0);

  const searchForCompany = async () => {
    const idNumber = form.getFieldValue("idNumber");
    const { data: responseData } =
      await publicApi.apiPublicOrganizationExistsIdNumberGet({ idNumber });
    if (responseData) {
      form.setFields([
        {
          name: "idNumber",
          errors: [
            t(
              "request:idNumber-exist",
              "You are already part of the system, please sign in with your credentials and make requests from there!"
            ),
          ],
        },
      ]);
    }
  };

  const searchForContactPersonEmail = async () => {
    const contactPersonEmail = form.getFieldValue("contactPersonEmail");
    const { data } = await publicApi.apiPublicUserExistsEmailGet({
      email: contactPersonEmail,
    });
    if (data) {
      form.setFields([
        {
          name: "contactPersonEmail",
          errors: [
            t(
              "request:conctactPersonEmail-exist",
              "You are already part of the system, please sign in with your credentials and make requests from there!"
            ),
          ],
        },
      ]);
    }
  };

  const handleCheckboxChange = (checked: any) => {
    form.setFieldValue("meetsEquipmentRegulations", checked);
  };

  const handleFileUpload = (info: UploadChangeParam) => {
    form.setFieldValue(
      "attachments",
      info.fileList.map((x) => x.originFileObj)
    );
  };

  const handleFileRemove = (fileToRemove: UploadFile) => {
    const currentFiles = form.getFieldValue("attachments") || [];
    const newFiles = currentFiles.filter(
      (file: UploadFile) => file.uid !== fileToRemove.uid
    );
    form.setFieldValue("attachments", newFiles);
    // Return true to confirm the removal, or you can return a promise if you have asynchronous operations
    return true;
  };

  const onMunicipalityChange = (value: number | undefined) => {
    const municipality = municipalities.find(
      (item: MunicipalityDto) => item.id === value
    );
    if (municipality) {
      form.setFieldsValue({
        cantonId: municipality.cantonId,
        entityId: municipality.stateEntityId,
      });
    }
  };

  const onNewCertificateAdded = async (c: any) => {
    if (!c) return;
    try {
      setCertificateNumberValidity({
        checking: true,
        valid: true,
        errorMessage: "",
      });

      setCertificateNumbers((x) => [...x, c]);

      const { data: responseData } =
        await publicApi.apiPublicRequestsCertificateNumbersValidityPost({
          checkCertificateNumberRequestDto: {
            certificationNumbers: [c],
            organizationId: null,
          },
        });
      const { isAvailable, certifiedTechnicianFullName } = responseData[0];
      setCertificateNumberValidity({
        checking: false,
        valid: isAvailable!,
        errorMessage: isAvailable
          ? ""
          : t(
            "requests:create-certificate-number-exists-message",
            "Certificate number {{dynamicValue}} is not available",
            { dynamicValue: responseData[0].certificateNumber }
          ),
      });

      if (!isAvailable) {
        setCertificateNumbers((c) => c.slice(0, -1));
      }

      setCertificateNumbersExpanded((x) => [
        ...x,
        `${c} (${certifiedTechnicianFullName})`,
      ]);
    } catch (error) {
      setCertificateNumberValidity((x) => ({
        ...x,
        valid: false,
        errorMessage: t("global.server-error", "Unexpected server error"),
      }));
    } finally {
      setCertificateNumberValidity((x) => ({ ...x, checking: false }));
    }
  };

  /**
   * Use Effect
   */
  useEffect(() => {

    var companyTypeName = t(`requests:company-type-${1}`)
    console.log(companyTypeName, "companyTypeName");

    const fetchBusinessActivities = async () => {
      const { data } =
        await publicApi.apiPublicCodebooksBusinessActivitiesGet();
      setBusinessActivities(data);
    };

    fetchBusinessActivities();
  }, []);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedType]);

  return (
    <>
      {/* <div className="request-overlay"> */}
      {parsedType === 1 && (
        <div className="request-overlay overlay-1">
          <p className="text-3xl font-semibold">
            {t(
              "request:owners&Operators:title",
              "Create Request For Registration Of Owners And Operators Of KGH Equipment."
            )}
          </p>
        </div>
      )}
      {parsedType === 2 && (
        <div className="request-overlay overlay-2">
          <p className="text-3xl font-semibold">
            {t(
              "request:service-companies:title",
              "Create Request For Registration And Licensing Of KGH Service Companies/Entrepreneurs."
            )}
          </p>
        </div>
      )}
      {parsedType === 3 && (
        <div className="request-overlay overlay-3">
          <p className="text-3xl font-semibold">
            {t(
              "request:importers-exporters",
              "Create Request For Registration And Licensing Of Importers/Exporters Of KGH Equipment."
            )}
          </p>
        </div>
      )}
      {/* </div> */}
      <div className="request-container">
        <Form
          form={form}
          name="request"
          requiredMark
          className="mt-5"
          onFinish={onFinish}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          scrollToFirstError
        >
          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-information", "Company Information")}
              </h1>
              <div className="flex flex-col">
                <Form.Item
                  name="companyName"
                  label={t("requests:create.company-name", "Company Name")}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(
                        "request:create-companyName",
                        "Company Name is required"
                      ),
                    },
                  ]}
                >
                  <Input className="w-full" required />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="idNumber"
                  label={t("requests:create.id-number", "ID Number")}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(
                        "request:create-id-number-required-validation",
                        "ID Number is required"
                      ),
                    },
                    {
                      len: 13,
                      message: t(
                        "request:create-id-number-length-validation",
                        "ID Number should be at most 13 characters"
                      ),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    // suffix={
                    //   !loggedAsCompany && (
                    //     <FeatherIcon
                    //       style={{ cursor: "pointer" }}
                    //       icon="search"
                    //       size={14}
                    //       onClick={() => searchForCompany()}
                    //     />
                    //   )
                    // }
                    required
                    onBlur={searchForCompany}
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="taxNumber"
                  label={t("requests:create.tax-number", "Tax Number")}
                  rules={[
                    {
                      len: 12,
                      required: true,
                      message: t(
                        "request:create-tax-number-validation",
                        "Tax Number should be at most 12 characters"
                      ),
                    },
                  ]}
                >
                  <Input className="w-full" required />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                {(isRegistrationOfServiceCompany ||
                  isRegistrationOfImportersExporters) && (
                    <Form.Item
                      name="areaOfExpertise"
                      label={t(
                        "global.select-areaOfExpertise",
                        "Select Area of Expertise"
                      )}
                      required
                      rules={[
                        {
                          required: true,
                          message: t(
                            "validations.area-of-expertise",
                            "Please select a Area of Expertise"
                          ),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option!.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                        className="sDash_fullwidth-select"
                        style={{ color: "rgb(90, 95, 125)" }}
                        aria-required
                      >
                        {Object.values(AreaOfExpertise)
                          .filter((value) => typeof value === "number")
                          .map((expertise) => {
                            const description = t(
                              `requests:area-of-expertise.${expertise}`,
                              AreaOfExpertiseDescriptions[
                              expertise as AreaOfExpertise
                              ]
                            );

                            if (typeof description !== "string") {
                              return null; // or some other fallback
                            }

                            return (
                              <Option key={expertise} value={expertise}>
                                {description}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
              </div>
              <div className="flex flex-col">
                {isRegistrationOfServiceCompany && (
                  <Form.Item
                    name="businessActivityId"
                    required
                    label={t("global.business-activity", "Business Activity")}
                    // requiredMark
                    rules={[
                      {
                        required: true,
                        message: t(
                          "validations.business-activity",
                          "Please select a Business Activity"
                        ),
                      },
                    ]}
                  >
                    <Select
                      className="sDash_fullwidth-select"
                      aria-required
                      size="large"
                      style={{ color: "rgb(90, 95, 125)" }}
                    >
                      {businessActivities &&
                        businessActivities?.map((item: CodebookDto) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              </div>
              <div className="flex flex-col">
                {(isRegistrationOfServiceCompany ||
                  isRegistrationOfImportersExporters) && (
                    <Form.Item
                      name="companyType"
                      required
                      label={t("requests:create.company-type", "Company Type")}
                      // requiredMark
                      rules={[
                        {
                          required: true,
                          message: t(
                            "validations.select-entity",
                            "Please select company type"
                          ),
                        },
                      ]}
                    >
                      <Select
                        className="sDash_fullwidth-select"
                        size="large"
                        aria-required
                        style={{ color: "rgb(90, 95, 125)" }}
                      >
                        {isRegistrationOfServiceCompany && (
                          <>
                            <Option
                              key={`company-type-${RequestCompanyType.NUMBER_1}`}
                              value={RequestCompanyType.NUMBER_1}
                            >
                              {t(
                                `requests:company-type.${RequestCompanyType.NUMBER_1}}`,
                                "Company"
                              )}
                            </Option>
                            <Option
                              key={`company-type-${RequestCompanyType.NUMBER_2}`}
                              value={RequestCompanyType.NUMBER_2}
                            >
                              {t(
                                `requests:company-type.${RequestCompanyType.NUMBER_2}}`,
                                "Entrepreneur"
                              )}
                            </Option>
                          </>
                        )}
                        {isRegistrationOfImportersExporters && (
                          <>
                            <Option
                              key={`company-type-${RequestCompanyType.NUMBER_3}`}
                              value={RequestCompanyType.NUMBER_3}
                            >
                              {t(
                                `requests:company-type-${RequestCompanyType.NUMBER_3}}`,
                                "Importer"
                              )}
                            </Option>
                            <Option
                              key={`company-type-${RequestCompanyType.NUMBER_4}`}
                              value={RequestCompanyType.NUMBER_4}
                            >
                              {t(
                                `requests:company-type-${RequestCompanyType.NUMBER_4}}`,
                                "Exporter"
                              )}
                            </Option>
                            <Option
                              key={`company-type-${RequestCompanyType.NUMBER_5}`}
                              value={RequestCompanyType.NUMBER_5}
                            >
                              {t(
                                `requests:company-type-${RequestCompanyType.NUMBER_5}}`,
                                "Importer/Exporter"
                              )}
                            </Option>
                          </>
                        )}
                      </Select>
                    </Form.Item>
                  )}
              </div>
            </div>
          </div>
          <div className="section-container flex flex-col md:flex-row justify-center">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-location", "Company Location")}
              </h1>
              <div className="flex flex-col">
                <Form.Item
                  name="address"
                  label={t("global.address", "Address")}
                >
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item name="place" label={t("global.place", "Place")}>
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="municipalityId"
                  label={t("global.select-municipality", "Select Municipality")}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(
                        "validations.select-municipality",
                        "Please select Municipality"
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    filterOption={(input, option) =>
                      option!.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="sDash_fullwidth-select"
                    style={{ color: "rgb(90, 95, 125)" }}
                    aria-required
                    onChange={onMunicipalityChange}
                  >
                    {municipalities &&
                      municipalities.map((item: MunicipalityDto) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="cantonId"
                  label={t("global.select-canton", "Select Canton")}
                >
                  <Select
                    className="sDash_fullwidth-select"
                    aria-required
                    size="large"
                    style={{ color: "rgb(90, 95, 125)" }}
                    disabled={true}
                  >
                    {cantons &&
                      cantons.map((item: CantonDto) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="entityId"
                  label={t("global.select-entity", "Select Entity")}
                // requiredMark
                >
                  <Select
                    className="sDash_fullwidth-select"
                    aria-required
                    size="large"
                    style={{ color: "rgb(90, 95, 125)" }}
                    disabled={true}
                  >
                    {stateEntities &&
                      stateEntities.map((item: StateEntityDto) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="section-container flex flex-col md:flex-row justify-center">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-contact", "Company Contact Information")}
              </h1>
              <div className="flex flex-col">
                <Form.Item
                  name="responsiblePersonFullName"
                  label={t(
                    "requests:create.responsible-person",
                    "Responsible person (full name)"
                  )}
                  required
                >
                  <Input className="w-full" required />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="responsiblePersonFunction"
                  label={t(
                    "requests:create.responsible-person-role",
                    "Responsible person function"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "users.create:responsible-person-function",
                        "Responsible person function is required"
                      ),
                    },
                  ]}
                  required
                >
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="companyEmailAddress"
                  label={t("request:company-email", "Company Email Address")}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "users.create:email-required",
                        "Email is required"
                      ),
                    },
                    {
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t(
                        "users.create:email-validation",
                        "Invalid email format"
                      ),
                    },
                  ]}
                  required
                >
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="companyPhoneNumber"
                  label={t("global.phone-number", "Phone number")}
                >
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="websiteUrl"
                  label={t("global.website", "Website url")}
                >
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonFirstName"
                  label={t(
                    "requests:create.contact-person-name",
                    "Contact Person's Name"
                  )}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(
                        "users.create:contact-person",
                        "Contact Person's Name is required"
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonLastName"
                  label={t(
                    "requests:create.contact-person-lastname",
                    "Contact Person's Lastname"
                  )}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(
                        "users.create:contact-person-lastname",
                        "Contact Person's LastName is required"
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonEmail"
                  label={t(
                    "requests:create.contact-person-email",
                    "Contact Person Email"
                  )}
                  tooltip={t(
                    "requests:create.contact-person-email-to-login",
                    "This email will be used for logging into the app"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "users.create:email-required",
                        "Email is required"
                      ),
                    },
                    {
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t(
                        "users.create:email-validation",
                        "Invalid email format"
                      ),
                    },
                  ]}
                  required
                >
                  <Input value={""} onBlur={searchForContactPersonEmail} />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="comments"
                  label={t("global.comment", "Comment")}
                >
                  <TextArea style={{ width: "100%" }} rows={3} />
                </Form.Item>
              </div>
            </div>
          </div>
          {type?.toString() === "2" && (
            <div className="section-container flex flex-col md:flex-row justify-center">
              <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
                <h1 className="text-lg font-medium">
                  {t(
                    "request:certificate-license",
                    "Certificate & License Information"
                  )}
                </h1>
                <div className="flex flex-col">
                  <Form.Item
                    name="certificationNumbers"
                    required
                    rules={[
                      {
                        required: true,
                        message: t(
                          "certified-service",
                          "Certified service technicians is required"
                        ),
                      },
                    ]}
                    label={t(
                      "requests:create-certificate-service-technicians",
                      "Certified service technicians"
                    )}
                    hasFeedback={certificateNumberValidity.checking}
                    validateStatus={
                      !certificateNumberValidity.valid
                        ? "error"
                        : certificateNumberValidity.checking
                          ? "validating"
                          : undefined
                    }
                    help={
                      !certificateNumberValidity.valid
                        ? certificateNumberValidity.errorMessage
                        : certificateNumberValidity.checking
                          ? t(
                            "requests:create-certificate-numbers-check-validity",
                            "Checking validity of {{dynamicValue}}",
                            { dynamicValue: certificateNumbers.at(-1) }
                          )
                          : undefined
                    }
                  >
                    <TagInput>
                      <Tag
                        animate
                        onInsert={onNewCertificateAdded}
                        data={certificateNumbersExpanded}
                        buttonLabel={"Serial Number"}
                        lastTagValid={certificateNumberValidity.valid}
                        isDoingServerCheck={certificateNumberValidity.checking}
                        title={t(
                          "requests:create-certificate-number-add",
                          "Add certificate number"
                        )}
                      />
                    </TagInput>
                  </Form.Item>
                </div>
                <div className="flex flex-col">
                  <Form.Item
                    name="totalNumberOfServiceTechnians"
                    label={t(
                      "requests:create-total-service-technicians",
                      "Total number of service technians"
                    )}
                    initialValue={0}
                  >
                    <Input type="number" />
                  </Form.Item>
                </div>

                <div className="flex flex-col">
                  {isRegistrationOfServiceCompany && (
                    <Form.Item>
                      <Checkbox onChange={handleCheckboxChange} checked={true}>
                        {t(
                          "requests:details.has-needed-equipment-check",
                          "Has needed equipment according to the proper regulations"
                        )}
                      </Checkbox>
                    </Form.Item>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="section-container flex flex-col md:flex-row justify-center">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:upload-document", "Upload Document")}
              </h1>
              <div className="flex flex-col">
                <Form.Item
                  name="attachments"
                  label={t(
                    "requests:create.upload-attachments",
                    "Upload attachments"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "requests:upload-document-required",
                        "Upload attachments is required"
                      ),
                    },
                  ]}
                  required
                >
                  <Upload
                    onChange={handleFileUpload}
                    onRemove={handleFileRemove}
                    beforeUpload={() => false}
                    multiple={true}
                  >
                    <Button
                      className="btn-outlined"
                      size="large"
                    // type="light"
                    // outlined
                    >
                      <UploadOutlined /> {t("global.upload", "Upload")}
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="section-container flex flex-col md:flex-row w-full mt-4">
            <div className="flex justify-end flex-col md:flex-row w-full">
              <Button
                className="btn-next bg-white mb-3 md:mb-0"
                type="default"
                disabled={isSubmitting}
                size="large"
                htmlType="reset"
              >
                {t("requests:create.cancel-button", "Cancel")}
              </Button>
              <Button
                className="btn-next bg-blue-500 ml-0 md:ml-4"
                type="primary"
                // disabled={!isFormValid()}
                htmlType="submit"
                size="large"
                loading={isSubmitting}
              >
                {t("requests:create.submit-button", "Submit")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateRequestsForm;
