import { Card, Col, Collapse, Row, Spin, Button, Modal } from "antd";
import { PublicApi } from "api/api";
// import { PageHeader } from "components/page-headers/page-headers";
import { CardToolbox, Main, PageHeaderWrapper } from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import { Modal } from "components/modals/antd-modals";
// import EquipmentForm, { EquipmentViewMode } from "pages/equipments/components/EquipmentForm";

import FeatherIcon from "feather-icons-react";
import { RegisterDetailsDto } from "api/models/register-details-dto";
import { EquipmentDto } from "api/models/equipment-dto";
import { BranchWithEquipmentsDetailsDto } from "api/models/branch-with-equipments-details-dto";
import "./register-details.css";
import EquipmentForm from "components/register/details/EquipmentForm";
const { Panel } = Collapse;

const Loader = styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  justify-content: center;
  justifyitems: center;
  align-items: center;
`;

const CardKeyValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    color: #323548;
    font-size: 13px;
    display: block;
    font-weight: bold;
    margin-bottom: 3px;
  }

  p {
    font-weight: 500;
  }
`;

const registersApi = new PublicApi();

export const RegisterDetailsPage = () => {
  const params = useParams();
  const [details, setDetails] = useState<RegisterDetailsDto>({});
  const [loading, setLoading] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDto>({});
  const [showEquipmentDetailsModal, setShowEquipmentDetailsModal] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const response = await registersApi.apiPublicRegistersIdGet({
          id: params.registerId as string,
        });
        setDetails(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (params.registerId) fetchDetails();
  }, [params.registerId]);

  const openEquipmentDetailsModal = (equipment: EquipmentDto) => {
    setSelectedEquipment(equipment);
    setShowEquipmentDetailsModal(true);
  };

  return (
    <div className="register-container">
      {loading ? (
        <Loader>
          <Spin />{" "}
        </Loader>
      ) : (
        <>
          <CardToolbox>
            <PageHeaderWrapper
              title={`${t(
                "registers:details-of-title",
                "Details of"
              )} ${"details.companyName"}`}
            />
          </CardToolbox>
          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-information", "Company Information")}
              </h1>
              <div className="flex flex-col">
                <span className="text-slate-700">
                  {t("requests:details.company-name", "Company name")}
                </span>
                <b>{details.companyName || "-"}</b>
              </div>
              <div className="flex flex-col mt-4">
                <span className="text-slate-700">
                  {t("requests:details.id-number", "Id number")}
                </span>
                <b>{details.companyIdNumber}</b>
              </div>
              <div className="flex flex-col mt-4">
                <span className="text-slate-700">
                  {t("requests:details.tax-number", "Tax number")}
                </span>
                <b>{details.companyTaxNumber}</b>
              </div>
              {details.isKghService && (
                <>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t("requests:details.company-type", "Company Type")}
                    </span>
                    <b>{details.companyType}</b>
                  </div>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t(
                        "requests:details.business-activity",
                        "Business Activity"
                      )}
                    </span>
                    <b>{details.companyActivity}</b>
                  </div>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t("requests:details.license-id", "License Id")}
                    </span>
                    <b>{details.companyLicenseId}</b>
                  </div>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t(
                        "requests:details.license-duration",
                        "License Duration"
                      )}
                    </span>
                    <b>
                      {details.companyLicenseDuration
                        ? moment(details.companyLicenseDuration).format(
                            "MM.DD.yyyy"
                          )
                        : "-"}
                    </b>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-location", "Company Location")}
              </h1>
              <div className="flex flex-col ">
                <span className="text-slate-700">
                  {t("requests:details.address", "Address")}
                </span>
                <b>{details.companyAddress}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.place", "Place")}
                </span>
                <b>{details.companyPlace}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.municipality", "Municipality")}
                </span>
                <b>{details.companyMunicipality}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.canton", "Canton")}
                </span>
                <b>{details.companyCanton}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.entity", "Entity")}
                </span>
                <b>{details.companyEntity}</b>
              </div>
            </div>
          </div>
          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-contact", "Company Contact Information")}
              </h1>
              <div className="flex flex-col  ">
                <span className="text-slate-700">
                  {t("requests:details.contact-person", "Contact Person")}
                </span>
                <b>{details.companyContactPerson}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t(
                    "requests:details.contact-person-email",
                    "Contact Person Email"
                  )}
                </span>
                <b>{details.companyContactPersonEmail}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.company-email", "Company Email Address")}
                </span>
                <b>{details.companyEmail}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.phone-number", "Phone Number")}
                </span>
                <b>{details.companyPhoneNumber}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("requests:details.website", "Website")}
                </span>
                <b>{details.companyWebsite}</b>
              </div>
            </div>
          </div>

          {(details.isKghService || details.isImporterExporter) && (
            <div className="section-container flex justify-center flex-col md:flex-row ">
              <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
                <h1 className="text-lg font-medium">
                  {t(
                    "request:certificate-license",
                    "Certificate & License Information"
                  )}
                </h1>
                {details.isImporterExporter && (
                  <>
                    <div className="flex flex-col ">
                      <span className="text-slate-700">
                        {t("requests:details.licenseId", "License ID")}
                      </span>
                      <b>{details.companyLicenseId}</b>
                    </div>
                    <div className="flex flex-col  mt-4">
                      <span className="text-slate-700">
                        {t(
                          "requests:details.licenseDuration",
                          "License Duration"
                        )}
                      </span>
                      <b>{details.companyLicenseDuration}</b>
                    </div>
                  </>
                )}
                {details.isKghService &&
                  details.certificationNumbers?.length !== 0 && (
                    <div className="flex flex-col  mt-4">
                      <span className="text-slate-700">
                        {t(
                          "global:certified-service-technicians",
                          "Certified Service Technicians"
                        )}
                      </span>
                      {details.certificationNumbers?.map((x, i) => (
                        <b>
                          {i + 1}. {x.certificateNumber}{" "}
                          {x.certifiedTechnicianFullName &&
                            `(${x.certifiedTechnicianFullName})`}
                          <br />
                        </b>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          )}
          {details.isOwnerAndOperator && (
            <>
              <div className="flex justify-center">
                <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
                  <h1 className="text-lg font-medium">
                    {t("global:branches", "Branches")}
                  </h1>

                  <br />
                  <Col xs={24}>
                    {details.branchesWithEquipments && (
                      <>
                        {/* <strong>{t("global:branches", "Branches")}</strong> */}
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                          {details.branchesWithEquipments.map(
                            (branch: BranchWithEquipmentsDetailsDto) => (
                              <Collapse
                                style={{
                                  background: "white !important",
                                  borderRadius: 2,
                                  marginBottom: 20,
                                }}
                              >
                                <Panel
                                  key={branch.email as any}
                                  header={<strong>{branch.name}</strong>}
                                  style={{
                                    background: "white !important",
                                    borderRadius: 2,
                                  }}
                                  forceRender
                                >
                                  <Row>
                                    <Col xs={12}>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t(
                                            "global:contact-person",
                                            "Contact Person"
                                          )}
                                        </span>
                                        <p>{branch.contactPerson}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t("global:email", "Email")}
                                        </span>
                                        <p>{branch.email}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t(
                                            "global:phone-number",
                                            "Phone number"
                                          )}
                                        </span>
                                        <p>{branch.contactPhone}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t("global:phone-number", "Address")}
                                        </span>
                                        <p>{branch.address}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t("global:place", "Place")}
                                        </span>
                                        <p>{branch.place}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t(
                                            "global:municipality",
                                            "Municipality"
                                          )}
                                        </span>
                                        <p>{branch.municipality}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t("global:canton", "Canton")}
                                        </span>
                                        <p>{branch.canton}</p>
                                      </CardKeyValue>
                                      <CardKeyValue className="mt-2">
                                        <span>
                                          {t("global:entity", "Entity")}
                                        </span>
                                        <p>{branch.entity}</p>
                                      </CardKeyValue>
                                    </Col>
                                    <Col xs={12}>
                                      {branch.equipments && (
                                        <>
                                          <b>
                                            {t(
                                              "global:equipments",
                                              "Equipments"
                                            )}
                                          </b>
                                          <br />
                                          {branch.equipments.map(
                                            (
                                              equipment: EquipmentDto,
                                              index: number
                                            ) => (
                                              <Row>
                                                <Button
                                                  onClick={() =>
                                                    openEquipmentDetailsModal(
                                                      equipment
                                                    )
                                                  }
                                                  type="link"
                                                  className="mt-2"
                                                >
                                                  <FeatherIcon
                                                    icon="eye"
                                                    size={14}
                                                  />{" "}
                                                  &nbsp;{" "}
                                                  {equipment.refrigerantType} (
                                                  {equipment.serialNumber})
                                                </Button>
                                              </Row>
                                            )
                                          )}
                                        </>
                                      )}
                                      {branch.equipments?.length === 0 && (
                                        <span>-</span>
                                      )}
                                    </Col>
                                  </Row>
                                </Panel>
                              </Collapse>
                            )
                          )}
                        </div>
                      </>
                    )}
                    {details.branchesWithEquipments?.length === 0 && (
                      <span>-</span>
                    )}
                  </Col>
                </div>
              </div>
            </>
          )}
          {showEquipmentDetailsModal && (
            <Modal
              width={800}
              footer={null}
              visible={showEquipmentDetailsModal}
              onCancel={() => {
                setShowEquipmentDetailsModal(false);
                setSelectedEquipment({});
              }}
            >
              <div className="project-modal">
                <EquipmentForm
                  mode={"VIEW"}
                  equipment={selectedEquipment}
                  showArchiveButton={false}
                />
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};
