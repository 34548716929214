import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import translationEN from 'utility/locales/en.json';
import translationBS from 'utility/locales/bs.json';
import translationHR from 'utility/locales/hr.json';
import translationSR from 'utility/locales/sr.json';

// const basePath = window.location.hostname === 'localhost' ? "https://localhost:7111/" : "/";
// var basePath = "https://dev.194-36-88-104.cloud-xip.com/api/public";

const resources = {
    bs: {
        translation: translationBS
    },
    sr: {
        translation: translationSR
    },
    hr: {
        translation: translationHR
    },
    en: {
        translation: translationEN
    }
}

const options = {
    order: ["navigator", "localStorage"],
    caches: ["localStorage"],
    resources,
    lng: localStorage.getItem('i18nextLng') || 'bs',
    fallbackLng: [],
    load: "currentOnly",
    saveMissing: true, // send not translated keys to endpoint
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeparator: false,
    keySeparator: false,
};


i18n.use(initReactI18next).init(options);