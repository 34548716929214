import React from 'react';
import './registerListItem.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const OperatorItem = ({ name, registerId, companyType, address, licenseId, licenseDuration, nrOfCertifiedServiceTechnicians, t }) => (
    <div className='operator-item'>
        <div className="column">
            <h3 className='text-lg'>{t('register-list:company', 'Company:')} <strong> {name}</strong></h3>
            {companyType ? (<div className='address'>
                <svg style={{ width: 24, height: 24 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                </svg>
                <p>{t('register-list:company-type', 'Company Type')} <strong>{companyType}</strong></p>
            </div>) : null}

            {licenseId ? (
                <div className='address'>
                    <svg style={{ width: 24, height: 24 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>

                    <p>{t('register-list:license-id', 'License Id:')}  <strong>{licenseId}</strong></p>
                </div>
            ) : null}

            {licenseDuration ? (
                <div className='address'>
                    <svg style={{ width: 24, height: 24 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                    </svg>


                    <p>{t('register-list:license-duration', 'License Duration:')} <strong>{moment(licenseDuration).format('DD-MM-YYYY')}</strong></p>
                </div>
            ) : null}

            {nrOfCertifiedServiceTechnicians ? (
                <div className='address'>
                    <svg style={{ width: 24, height: 24 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                    </svg>

                    <p>{t('register-list:number-of-service', 'Nr of certified service technicians:')} <strong>{nrOfCertifiedServiceTechnicians}</strong></p>
                </div>
            ) : null}

            <div className='address'>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                    style={{ width: 24, height: 24 }}
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
                <p>{t('register-list:municipality', 'Municipality:')} <strong>{address}</strong></p>
            </div>
        </div>
        <div className='action'>
            <Link to={`/register-details/${registerId}`}>
                <button>{t('global:details', 'Details')}</button>
            </Link>

            {/* 
            <button>Uređaji</button> */}
        </div>
    </div>
);

const RegisterListItem = ({ searchResults }) => {
    const { t } = useTranslation();

    return (
        <div className='register-list-container mt-28 md:mt-12'>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <span className='operators'>{t('register-list:number-of-results', '{{dynamicValue}} results', { dynamicValue: searchResults?.length })}</span>
            </div>
            {searchResults.length > 0 ? (searchResults?.map((operator, index) => (
                <OperatorItem
                    key={index}
                    name={operator.companyName}
                    registerId={operator.id}
                    companyType={operator.companyType}
                    address={operator.municipality}
                    licenseId={operator.licenseId}
                    licenseDuration={operator.licenseDuration}
                    nrOfCertifiedServiceTechnicians={operator.nrOfCertifiedServiceTechnicians}
                    t={t}
                />
            ))) : (<p>{t('register-list:loading', 'Loading...')}</p>)}
        </div>
    );
};

export default RegisterListItem;
