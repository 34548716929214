import { useState, useEffect } from 'react';

import { CommonDataContext } from './CommonDataContext';
import { PublicApi } from 'api/api';

const publicApi = new PublicApi();


// import { hasPermission } from 'utility/accessibility/hasPermission';

export const CommonDataProvider = ({ children }) => {
  const [commonData, setCommonData] = useState({
    cantons: [],
    // userGroups: [],
    // languages: [],
    stateEntities: [],
    municipalities: [],
    isLoading: false
  });

  useEffect(() => {
    const fetchCommonData = async () => {
      setCommonData((prev) => ({ ...prev, isLoading: true }))
      try {
        const [
          // languagesResponse,
          stateEntitiesResponse,
          municipalitiesResponse,
          cantonsResponse, 
          // userGroupsResponse,
        ] = await Promise.all([
          // languagesApi.apiLanguagesForUiGet(),
          publicApi.apiPublicCodebooksStateEntitiesGet({ pageSize: -1 }),
          publicApi.apiPublicCodebooksMunicipalitiesGet({ pageSize: -1 }),
          publicApi.apiPublicCodebooksCantonsGet({ pageSize: -1 }),
        ]);

        setCommonData({
          // languages: languagesResponse?.data,
          stateEntities: stateEntitiesResponse?.data,
          municipalities: municipalitiesResponse?.data,
          cantons: cantonsResponse?.data,
          // userGroups: userGroupsResponse?.data,
        });

      } catch (error) {
        console.error(error);
      } finally {
        setCommonData((prev) => ({ ...prev, isLoading: false }))
      }
    };

    fetchCommonData();
  }, []);

  return (
    <CommonDataContext.Provider value={commonData}>
      {children}
    </CommonDataContext.Provider>
  );
};
