import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Spin,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { PublicApi } from "api/api";
import { UploadOutlined } from "@ant-design/icons";
import { AddProfile, BasicFormWrapper } from "container/styled";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { base64ToFile, downloadFilesFromBase64 } from "utility/dowloadFiles";
import openNotificationWithIcon from "utility/notification";
import { useNavigate } from "react-router-dom";
import Alert from "components/alerts/alerts";
const { Option } = Select;

// const equipmentsApi = new EquipmentsApi();
// const companyBranchesApi = new CompanyBranchApi();
// const codebooksApi = new CodebookApi();
// const refrigerantTypeApi = new RefrigerantTypesApi();

const publicApi = new PublicApi();

export const EquipmentViewMode = {
  VIEW: "VIEW",
  UPDATE: "UPDATE",
  CREATE: "CREATE",
};

const Loader = styled.div`
    display: flex; 
    height: 400px;
    width: 100% !important; 
    justify-content: center; 
    text-
    justifyItems: center; 
    align-items: center;
`;

const EquipmentForm = ({ mode, equipment, showArchiveButton = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState();
  const [fileList, setFileList] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [branches, setBranches] = useState();
  const [codebooks, setCodebooks] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [refrigerantTypes, setRefrigerantTypes] = useState();

  const [showEquipmentOther, setShowEquipmentOther] = useState();
  const [showCoolingSystemOther, setShowCoolingSystemOther] = useState();

  const readOnlyMode = mode === EquipmentViewMode.VIEW;
  const isUpdateMode = mode === EquipmentViewMode.UPDATE;

  useEffect(() => {
    const mapEquipmentDetails = async () => {
      const {
        typeOfEquipmentId,
        typeOfCoolingSystemId,
        refrigerantTypeId,
        massOfRefrigerantKg,
      } = equipment;

      equipment.typeOfCoolingSystemId = typeOfCoolingSystemId ?? -1;
      equipment.typeOfEquipmentId = typeOfEquipmentId ?? -1;
      equipment.dateOfPurchase = moment(equipment.dateOfPurchase);
      equipment.commissioningDate = moment(equipment.commissioningDate);
      equipment.yearOfProduction = moment().year(equipment.yearOfProduction);

      onRefrigerantChange(refrigerantTypeId);
      onChangeEquipmentType(equipment.typeOfEquipmentId);
      onChangeOfCoolingSystem(equipment.typeOfCoolingSystemId);
      onRefrigerantMassChange(massOfRefrigerantKg);

      setExistingFiles(
        equipment?.files?.map((file) => ({ fileName: file.fileName })) ?? []
      );
      setFileList(
        readOnlyMode ? equipment?.files : equipment?.files.map(base64ToFile)
      );

      form.setFieldsValue(equipment);
    };

    if (equipment) {
      mapEquipmentDetails();
    }
  }, [equipment, codebooks, refrigerantTypes, branches]);

  const onRefrigerantChange = (id) => {
    const refrigerantType = refrigerantTypes?.find((x) => x.id === id);
    form.setFieldValue("ashraeDesignation", refrigerantType?.ashraeDesignation);
    form.setFieldValue(
      "globalWarmingPotential",
      refrigerantType?.globalWarmingPotential
    );
    form.setFieldValue(
      "typeOfCoolingFluid",
      refrigerantType?.typeOfCoolingFluid
    );
  };

  const onChangeEquipmentType = (type) => {
    setShowEquipmentOther(type === -1);
    if (type === -1) {
      form.setFieldValue("equipmentTypeOther", "");
    }
  };

  const onChangeOfCoolingSystem = (type) => {
    setShowCoolingSystemOther(type === -1);
    if (type === -1) {
      form.setFieldValue("coolingSystemOther", "");
    }
  };

  const onRefrigerantMassChange = (value) => {
    const gwp = form.getFieldValue("globalWarmingPotential");
    const CO2Equivalent = (value * gwp) / 1000;
    form.setFieldValue("CO2Equivalent", CO2Equivalent);
  };

  useEffect(() => {
    const fetchCodebooks = async () => {
      const { data } = await publicApi.apiPublicCodebooksGet();
      setCodebooks(data);
    };

    const fetchRefrigerantTypes = async () => {
      const { data } = await publicApi.apiPublicRefrigerantTypesGet();
      setRefrigerantTypes(data.items);
    };

    // const fetchBranches = async () => {
    //     const response = await publicApi.apiCompanyBranchesGet({ pageSize: -1 });
    //     setBranches(response.data?.items);
    // };

    const fetchAllData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchCodebooks(),
        fetchRefrigerantTypes(),
        // fetchBranches()
      ]);

      setIsLoading(false);
    };

    fetchAllData();
  }, []);

  if (isLoading) {
    return (
      <Loader>
        <Spin />{" "}
      </Loader>
    );
  }

  return (
    <AddProfile>
      <BasicFormWrapper className="basic-form-inner">
        {readOnlyMode && equipment?.isArchived && (
          <Alert type={"warning"} message={"This equipmet was archived!"} />
        )}
        <div className="atbd-form-checkout">
          <Row justify="center">
            <Col xs={24}>
              <div className="create-account-form">
                <Form
                  form={form}
                  name="register_equipment"
                  requiredMark
                  aria-required
                >
                  <Row gutter={60}>
                    <Col span={12}>
                      <Form.Item
                        name="companyBranchId"
                        label={t(
                          "equipments:branch-office-label",
                          "Branch Office"
                        )}
                        requiredMark
                        required
                        rules={[
                          {
                            required: true,
                            message: t(
                              "validations.select-branch",
                              "Please Select a Branch Office"
                            ),
                          },
                        ]}
                      >
                        <Select
                          aria-required
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:branch-office-placeholder",
                            "Select a Branch Office"
                          )}
                        >
                          {branches &&
                            branches?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.branchOfficeName}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="typeOfEquipmentId"
                        label={t(
                          "equipments:type-of-equipment-label",
                          "Type of Equipment"
                        )}
                        requiredMark
                        required
                        rules={[
                          {
                            required: true,
                            message: t(
                              "validations.select-equipment-type",
                              "Please Select Type of Equipment"
                            ),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t(
                            "equipments:type-of-equipment-placeholder",
                            "Select a Type of Equipment"
                          )}
                          disabled={readOnlyMode}
                          onChange={onChangeEquipmentType}
                          aria-required
                        >
                          {codebooks["TypeOfEquipment"] &&
                            codebooks["TypeOfEquipment"]?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          <Option key={"TypeOfEquipment-other"} value={-1}>
                            {t("equipments:other", "Other")}
                          </Option>
                        </Select>
                      </Form.Item>
                      {showEquipmentOther && (
                        <>
                          <Form.Item
                            name="typeOfEquipmentOther"
                            label={t(
                              "equipments:typeOfEquipment-label",
                              "Type of Equipment (Other)"
                            )}
                          >
                            <Input
                              disabled={readOnlyMode}
                              placeholder={t(
                                "equipments:typeOfEquipment-label",
                                "Type of Equipment (Other)"
                              )}
                              required
                            />
                          </Form.Item>
                          <Divider />
                        </>
                      )}
                      <Form.Item
                        name="typeOfCoolingSystemId"
                        label={t(
                          "equipments:cooling-system-type-label",
                          "Type of Cooling System"
                        )}
                        requiredMark
                        required
                        rules={[
                          {
                            required: true,
                            message: t(
                              "validations.select-cooling-system",
                              "Please Select Type of Cooling System"
                            ),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t(
                            "equipments:cooling-system-type-placeholder",
                            "Select a Cooling System Type"
                          )}
                          onChange={onChangeOfCoolingSystem}
                          disabled={readOnlyMode}
                          aria-required
                        >
                          {codebooks["TypeOfCoolingSystem"] &&
                            codebooks["TypeOfCoolingSystem"]?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          <Option key={"typeOfCoolingSystem-other"} value={-1}>
                            {t("equipments:other", "Other")}
                          </Option>
                        </Select>
                      </Form.Item>
                      {showCoolingSystemOther && (
                        <>
                          <Form.Item
                            name="typeOfCoolingSystemOther"
                            label={t(
                              "equipments:typeOfCoolingSystemOther-label",
                              "Type of Cooling System (Other)"
                            )}
                          >
                            <Input
                              disabled={readOnlyMode}
                              placeholder={t(
                                "equipments:typeOfCoolingSystemOther-label",
                                "Type of Cooling System (Other)"
                              )}
                              required
                            />
                          </Form.Item>
                          <Divider />
                        </>
                      )}

                      <Form.Item
                        name="manufacturer"
                        label={t(
                          "equipments:manufacturer-label",
                          "Manufacturer"
                        )}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:manufacturer-placeholder",
                            "Manufacturer"
                          )}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        name="type"
                        label={t("equipments:type-label", "Type")}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t("equipments:type-placeholder", "Type")}
                        />
                      </Form.Item>

                      <Form.Item
                        name="model"
                        label={t("equipments:model-label", "Model")}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:model-placeholder",
                            "Model"
                          )}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        name="serialNumber"
                        label={t(
                          "equipments:serial-number-label",
                          "Serial Number"
                        )}
                        requiredMark
                        required
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:serial-number-placeholder",
                            "Serial Number"
                          )}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        name="yearOfProduction"
                        label={t(
                          "equipments:year-of-production-label",
                          "Year of Production"
                        )}
                      >
                        <DatePicker
                          picker="year"
                          disabled={readOnlyMode}
                          aria-required
                          placeholder={t(
                            "equipments:year-of-production-placeholder",
                            "Year of Production"
                          )}
                        />
                      </Form.Item>

                      <Form.Item
                        name="dateOfPurchase"
                        label={t(
                          "equipments:date-of-purchase-label",
                          "Date of Purchase"
                        )}
                        required
                      >
                        <DatePicker
                          placeholder={t(
                            "equipments:date-of-purchase-placeholder",
                            "Select Date of Purchase"
                          )}
                          format={"DD.MM.YYYY"}
                        />
                      </Form.Item>

                      <Form.Item
                        name="refrigerantTypeId"
                        label={t(
                          "equipments:refrigerant-type-label",
                          "Type of Refrigerant"
                        )}
                        requiredMark
                        required
                        rules={[
                          {
                            required: true,
                            message: t(
                              "validations.select-type-of-refrigerant",
                              "Please Select Type of Refrigerant"
                            ),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t(
                            "equipments:refrigerant-type-placeholder",
                            "Select a Refrigerant Type"
                          )}
                          disabled={readOnlyMode}
                          onChange={onRefrigerantChange}
                        >
                          {refrigerantTypes &&
                            refrigerantTypes?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="ashraeDesignation"
                        label={t(
                          "equipments:ashrae-designation-label",
                          "ASHRAE Refrigerant Designation"
                        )}
                      >
                        <Input
                          placeholder={t(
                            "equipments:ashrae-designation-placeholder",
                            "Autofilled"
                          )}
                          disabled
                        />
                      </Form.Item>

                      <Form.Item
                        name="typeOfCoolingFluid"
                        label={t(
                          "equipments:cooling-fluid-type-label",
                          "Type of Cooling Fluid"
                        )}
                      >
                        <Input
                          placeholder={t(
                            "equipments:cooling-fluid-type-placeholder",
                            "Autofilled"
                          )}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        name="globalWarmingPotential"
                        label={t(
                          "equipments:gwp-label",
                          "Global Warming Potential (GWP)"
                        )}
                      >
                        <Input
                          disabled
                          placeholder={t(
                            "equipments:gwp-placeholder",
                            "Autofilled"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="massOfRefrigerantKg"
                        label={t(
                          "equipments:refrigerant-mass-label",
                          "Mass of Refrigerant in the Device (kg)"
                        )}
                        required
                        onChange={(e) =>
                          onRefrigerantMassChange(e.target.value)
                        }
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:refrigerant-mass-placeholder",
                            "Mass of Refrigerant"
                          )}
                          type="number"
                        />
                      </Form.Item>

                      <Form.Item
                        name="CO2Equivalent"
                        label={t(
                          "equipments:co2-equivalent-label",
                          "CO2 Equivalent Tons"
                        )}
                        required
                      >
                        <Input
                          placeholder={t(
                            "equipments:co2-equivalent-placeholder",
                            "Autofilled"
                          )}
                          disabled
                        />
                      </Form.Item>

                      <Form.Item
                        name="purposeOfEquipmentId"
                        label={t(
                          "equipments:purpose-label",
                          "Purpose of Equipment"
                        )}
                        requiredMark
                        required
                        rules={[
                          {
                            required: true,
                            message: t(
                              "validations.select-purpose-of-equipment",
                              "Please Select Purpose of Equipment"
                            ),
                          },
                        ]}
                      >
                        <Select
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:purpose-placeholder",
                            "Select a Purpose"
                          )}
                        >
                          {codebooks["EquipmentPurposes"] &&
                            codebooks["EquipmentPurposes"]?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="coolingTemperature"
                        label={t(
                          "equipments:cooling-temperature-label",
                          "Cooling Temperature (°C)"
                        )}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:cooling-temperature-placeholder",
                            "Cooling Temperature"
                          )}
                          type="number"
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        name="coolingEffectKw"
                        label={t(
                          "equipments:cooling-effect-label",
                          "Cooling Effect (kW)"
                        )}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:cooling-effect-placeholder",
                            "Cooling Effect"
                          )}
                          type="number"
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        name="compressorConnectionPowerKw"
                        label={t(
                          "equipments:compressor-connection-power-label",
                          "Compressor Connection Power (kW)"
                        )}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:compressor-connection-power-placeholder",
                            "Compressor Connection Power"
                          )}
                          type="number"
                        />
                      </Form.Item>

                      <Form.Item
                        name="liquidCollectorVolume"
                        label={t(
                          "equipments:liquid-collector-volume-label",
                          "Liquid Collector Volume (L)"
                        )}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:liquid-collector-volume-placeholder",
                            "Liquid Collector Volume"
                          )}
                          type="number"
                        />
                      </Form.Item>

                      <Form.Item
                        name="massAddedLastYearInKg"
                        label={t(
                          "equipments:working-substance-mass-last-year-label",
                          "Mass of Working Substance Added Last Year (kg)"
                        )}
                      >
                        <Input
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:working-substance-mass-last-year-placeholder",
                            "Working Substance Mass Last Year"
                          )}
                          type="number"
                        />
                      </Form.Item>

                      <Form.Item
                        name="commissioningDate"
                        label={t(
                          "equipments:commissioning-date-label",
                          "Commissioning Date"
                        )}
                      >
                        <DatePicker
                          disabled={readOnlyMode}
                          placeholder={t(
                            "equipments:commissioning-date-placeholder",
                            "Select Commissioning Date"
                          )}
                          format={"DD.MM.YYYY"}
                        />
                      </Form.Item>

                      <Form.Item
                        name="comments"
                        label={t("equipments:comments-label", "Comments")}
                      >
                        <Input.TextArea
                          disabled={readOnlyMode}
                          rows={3}
                          placeholder={t(
                            "equipments:comments-placeholder",
                            "Enter Comments"
                          )}
                        />
                      </Form.Item>
                      {!readOnlyMode && (
                        <>
                          {" "}
                          <Form.Item
                            required
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "equipments.attachments",
                                  "Please upload files"
                                ),
                              },
                            ]}
                          >
                            <Dragger
                              fileList={fileList}
                              beforeUpload={(file) => {
                                setFileList([...fileList, file]);
                                return false;
                              }}
                              onRemove={(file) => {
                                if (isUpdateMode)
                                  setExistingFiles(
                                    [...existingFiles].map((x) => ({
                                      ...x,
                                      deleted: file.name.includes(x.fileName),
                                    }))
                                  );

                                const index = fileList.indexOf(file);
                                const newFileList = fileList.slice();
                                newFileList.splice(index, 1);
                                setFileList(newFileList);
                              }}
                            >
                              <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file(s) to this area to upload
                              </p>
                            </Dragger>
                          </Form.Item>
                          <div className="steps-action">
                            <Button
                              className="btn-next"
                              type="primary"
                              loading={isSaving}
                              htmlType="submit"
                              style={{
                                height: 50,
                                padding: "10px 20px",
                                float: "right",
                              }}
                            >
                              <FeatherIcon
                                icon="save"
                                size={24}
                                style={{ marginRight: 10 }}
                              />
                              {isUpdateMode
                                ? t("equipments:update-btn", "Update Equipment")
                                : t(
                                    "equipments:register-btn",
                                    "Register Equipment"
                                  )}
                            </Button>
                          </div>
                        </>
                      )}
                      {readOnlyMode && fileList?.length > 0 && (
                        <div style={{ marginBottom: 20 }}>
                          <div>
                            <FeatherIcon icon="download-cloud" size={15} />{" "}
                            <span
                              onClick={() => downloadFilesFromBase64(fileList)}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Download Files
                            </span>
                          </div>
                        </div>
                      )}
                      {showArchiveButton &&
                        readOnlyMode &&
                        !equipment?.isArchived && (
                          <div>
                            <Button
                              style={{
                                height: 50,
                                padding: "10px 20px",
                                float: "right",
                              }}
                              size="lg"
                              type="danger"
                            >
                              <FeatherIcon
                                icon="archive"
                                size={15}
                                style={{ marginRight: 15 }}
                              />{" "}
                              {t("equipments:btn-archive-it", "Archive")}
                            </Button>
                          </div>
                        )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </BasicFormWrapper>
    </AddProfile>
  );
};

export default EquipmentForm;
